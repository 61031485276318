<script lang="ts">
	import { services, expertises, industries, solutions } from '$lib/config/footer';

	import Logo from '$icons/exoft-white-logo.svelte';
	import ClutchIcon from '$icons/clutch-icon.svelte';
	import FacebookIcon from '$icons/facebook-icon.svelte';
	import TwitterIcon from '$icons/twitter-icon.svelte';
	import BehanceIcon from '$icons/behance-icon.svelte';
	import LinkedinIcon from '$icons/linkedin-icon.svelte';
	import GrowPadIcon from '$icons/growpad-logo.svelte'
</script>

<footer>
	<div class="container top flex-row">
		<div class="offices flex-column">
			<div class="logo">
				<a href="/" aria-label="Home">
					<Logo />
				</a>
			</div>
			
			<div class="list">
				<div class="column flex-column">
					<address>
						111A T. Shevchenko Str., Lviv Ukraine
					</address>
					<a href="tel:+380639631064">+38 063 963 1064</a>
					<a href="mailto:contactus@exoft.net">contactus@exoft.net</a>
				</div>
			</div>
		</div>
		
		<div class="flex-column">
			<nav class="flex-column footer-text">
				<div class="header footer-text">Services</div>
				
				{#each services as service}
					<a href="{service.url}">{service.text}</a>
				{/each}
			</nav>
			
			<br>
			
			<nav class="flex-column footer-text">
				<div class="header footer-text">Expertises</div>
				
				{#each expertises as service}
					<a href="{service.url}">{service.text}</a>
				{/each}
			</nav>
			<br>
			<div class="header footer-text">
				<a href="/technology/">Technology</a>
			</div>
		</div>
		
		<nav class="industries flex-column footer-text">
			<div class="header footer-text">Industries</div>
			
			{#each industries as industry}
				<a href="{industry.url}">{industry.text}</a>
			{/each}
		</nav>
		
		<div class="flex-column">
			<nav class="flex-column footer-text">
				<div class="header footer-text">Solutions</div>
				
				{#each solutions as solution}
					<a href="{solution.url}">{solution.text}</a>
				{/each}
			</nav>
		</div>
		
		<div class="flex-column">
			<nav class="technologies flex-column footer-text">
				<div class="header footer-text">About us</div>
				
				<a href="/about-us/">Who we are</a>
				<a href="/approach/">How we work</a>
				<a href="/our-clients/">Our clients</a>
				<a href="/our-team/">Our team</a>
				
				<div class="spacer"></div>
			</nav>
			<nav class="technologies flex-column footer-text">
				<a class="header footer-text" href="/our-works/">Our works</a>
				<a class="header footer-text" href="/blog/">Blog</a>
				<a class="header footer-text" href="/career/">Career</a>
			</nav>
		</div>
	</div>
	
	<div class="bottom">
		<div class="container links flex-row">
			<div class="icons flex-row">
				<a
					href="https://www.linkedin.com/company/exoft"
					target="_blank"
					rel="noopener noreferrer">
					<LinkedinIcon />
				</a>
				
				<a
					href="https://twitter.com/ExoftSoftware"
					target="_blank"
					rel="noopener noreferrer">
					<TwitterIcon />
				</a>
				
				<a
					href="https://www.behance.net/exoft/"
					target="_blank"
					rel="noopener noreferrer">
					<BehanceIcon />
				</a>
				
				<a href="https://www.facebook.com/exoft" target="_blank" rel="noopener noreferrer">
					<FacebookIcon />
				</a>
				
				<a
					href="https://clutch.co/profile/exoft"
					target="_blank"
					rel="noopener noreferrer">
					<ClutchIcon />
				</a>
			</div>
			<div class="marketing flex-row">
				<span class="footer-text">B2B inbound marketing with</span>
				<a href="https://growpad.pro/ua/"
					 target="_blank"
					 rel="noopener noreferrer">
					<GrowPadIcon/>
				</a>
			</div>
			<div class="credentials footer-text">
				<a href="/cookie-policy/">Cookie policy</a>
				<a href="/privacy-policy/">Privacy policy</a>
				<div>
					© 2013-2025 Exoft. All Rights Reserved
				</div>
			</div>
		</div>
	</div>
</footer>

<style lang="scss">
	@import '$styles/vars';

	footer {
		background: $second-black;
		color: $main-white;
		margin-top: 60px;
		padding-top: 24px;

		.top {
			justify-content: space-between;
			column-gap: 10px;
			padding-bottom: 30px;

			nav {
				margin-top: 7px;
				row-gap: 12px;

				.spacer {
					padding-bottom: 24px;
				}
			}
		}

		.bottom {
			background: $main-black;
			width: 100%;
		}
	}
 
	.footer-text {
		font-size: 14px;
		font-weight: 300;
		line-height: 22px;
		font-style: normal;
		color: $main-white;
	}

  .header {
		font-weight: 600;
  }

  a {
		&:hover {
			color: $green;
		}
  }

	.links {
		justify-content: space-between;
		height: 48px;
		align-items: center;
		margin-left: auto;
		margin-right: auto;

		.icons {
			align-items: center;
			column-gap: 10px;

			:global(svg) {
				border-radius: 6px;
				padding: 6px;
				background: $second-black;
				&:hover {
					fill: $green;

					:global(path) {
						fill: $green;
					}
				}
			}
		}
		
		.marketing {
			align-items: center;
			column-gap: 5px;
			a {
				display: inline-block;
				width: 95px;
				filter: grayscale(100%);
				&:hover {
		  		filter: none;
				}
			}
		}

		.credentials {
			display: flex;
			column-gap: 9px;
			align-items: center;
		}
	}

	.offices {
		padding-bottom: 48px;
		display: block;
		.logo {
			margin-bottom: 32px;
		}

		.column {
			row-gap: 12px;
			font-weight: 400;
			font-size: 12px;
			line-height: 22px;
			
			address {
				font-style: normal;
			}
		}
	}
</style>
