<script lang="ts">
	import { menuTree } from '$lib/config/menu-tree';
	import Logo from '$icons/exoft-white-logo.svelte';
	import ArrowIcon from '$icons/arrow-expand.svelte';
	import ArrowIconGreen from '$icons/arrow-expand-green.svelte';
	import type { MenuLink } from '$lib/domain/menu-link.interface';


	let mobileMenuOpen: true | false = false;
	let currentMenuItemActive: string | undefined;
	let currentMenuParentItemActive: string | undefined;

	function toggleServiceMenu(menuItem?: MenuLink): void {
		if (!menuItem?.submenues?.length) {
			mobileMenuOpen = false;
			currentMenuItemActive = menuItem?.text;
			return;
		}

		currentMenuParentItemActive =
			currentMenuParentItemActive === menuItem?.text && !!menuItem.submenues.length
				? undefined
				: menuItem.text;
	}
</script>

<div
	class:blur="{mobileMenuOpen}"
	on:click={(e) => (e.currentTarget === e.target) ? (mobileMenuOpen = !mobileMenuOpen): undefined  }
	on:keydown={(e) => (e.currentTarget === e.target) ? (mobileMenuOpen = !mobileMenuOpen): undefined  }
>
	<header class:sliding-header="{mobileMenuOpen}">
		<div class="logo">
			<a href="/" target="_self" aria-label="Home">
				<Logo />
			</a>
		</div>

		<button
				id="hamburger"
				on:click="{() => (mobileMenuOpen = !mobileMenuOpen)}"
				class:open="{mobileMenuOpen}">
			<span></span>
			<span></span>
			<span></span>
			<span></span>
		</button>
	</header>
</div>

<div class="sliding-menu" class:opened="{mobileMenuOpen}">
	<div class="sliding-menu-items">
		{#each menuTree as menuItem}
			{#if !menuItem?.submenues?.length}
				<div class="menu-item">
					<a
							class="{currentMenuItemActive === menuItem.text
						? 'header-button active'
						: 'header-button'}"
							on:click="{() => {toggleServiceMenu(menuItem); currentMenuParentItemActive = undefined; }}"
							href="{menuItem.url}">
						{menuItem.text}
					</a>
				</div>
			{:else}
				<div class="menu-item-group">
					<button
							on:click="{() => toggleServiceMenu(menuItem) }"
							class="header-button {currentMenuParentItemActive === menuItem.text ? 'header-button-opened' :
						 	''}"
						>
						{menuItem.text}

						{#if (currentMenuParentItemActive === menuItem.text)}
							<ArrowIconGreen/>
						{:else}
							<ArrowIcon />
						{/if}
					</button>
					<div
						class="subitems-blocks"
						class:opened="{currentMenuParentItemActive === menuItem.text}">
						{#each menuItem.submenues as submenu}
							<a
								class="{currentMenuItemActive === submenu.text
									? 'header-button active'
									: 'header-button'}"
								on:click="{() => toggleServiceMenu(submenu)}"
								href="{submenu.url}">
								{submenu.text}
							</a>
							
							{#if (submenu.submenu)}
								<div class="niche">
									{#each submenu.submenu as subitem}
										<a
											class="{currentMenuItemActive === subitem.text
															? 'header-button active'
															: 'header-button'}"
											on:click="{() => toggleServiceMenu(subitem)}"
											href="{subitem.url}">
											{subitem.text}
										</a>
									{/each}
								</div>
							{/if}
						{/each}

						{#if (!!menuItem.footerText)}
							<div class="subitems-footer">
								<div class="footer-title">
									<a
										class="{currentMenuItemActive === menuItem.footerText
											? 'active': ''}"
										on:click="{() => toggleServiceMenu(menuItem.footerText)}"
										href="{menuItem.footerTextUrl}"
									>
										{menuItem.footerText}
									</a>
								</div>
								<div class="footer-items">
									{#each menuItem.footerSubmenues as footerSumnenu}
										<a
											class="{currentMenuItemActive === footerSumnenu.text
												? 'active': ''}"
											on:click="{() => toggleServiceMenu(footerSumnenu)}"
											href="{footerSumnenu.url}">
											{footerSumnenu.text}
										</a>
									{/each}
								</div>
							</div>
						{/if}
					</div>
				</div>
			{/if}
		{/each}
		<div class="bottom-part" style="padding-bottom: 30px">
			<a
					on:click="{() => {mobileMenuOpen = false; currentMenuParentItemActive = undefined;
                        currentMenuItemActive = undefined;}}"
					href="/contact-us/"
					type="button"
					class="action-button">
				Contact us</a>
		</div>
	</div>
</div>

<style lang="scss">
	@import '$styles/vars';

	.blur {
		position: fixed;
		background: rgba(26, 26, 26, 0.10);
		backdrop-filter: blur(12px);
		width: 100vw;
		height: 100%;
		transition: 0.3s;
		z-index: 10;
	}

	header {
		color: $main-white;
		background-color: $second-black;
		box-shadow: none;
		position: fixed;
		width: 100%;
		z-index: 10;
		align-items: center;
		justify-content: space-between;
		padding: 24px 36px;
		box-sizing: border-box;
		display: flex;
		transition: 0.3s;

		#hamburger {
			width: 34px;
			height: 24px;
			position: relative;
			-webkit-transform: rotate(0deg);
			-moz-transform: rotate(0deg);
			-o-transform: rotate(0deg);
			transform: rotate(0deg);
			-webkit-transition: 0.5s ease-in-out;
			-moz-transition: 0.5s ease-in-out;
			-o-transition: 0.5s ease-in-out;
			transition: 0.5s ease-in-out;
			cursor: pointer;
			background-color: transparent;
			border: none;
			outline: none;

			&.open {
				span {
					&:nth-child(1) {
						top: 18px;
						width: 0;
						left: 50%;
					}

					&:nth-child(2) {
						-webkit-transform: rotate(45deg);
						-moz-transform: rotate(45deg);
						-o-transform: rotate(45deg);
						transform: rotate(45deg);
					}

					&:nth-child(3) {
						-webkit-transform: rotate(-45deg);
						-moz-transform: rotate(-45deg);
						-o-transform: rotate(-45deg);
						transform: rotate(-45deg);
					}

					&:nth-child(4) {
						top: 18px;
						width: 0;
						left: 50%;
					}
				}
			}

			span {
				display: block;
				position: absolute;
				height: 3px;
				width: 100%;
				background: $main-white;
				border-radius: 6px;
				opacity: 1;
				left: 0;
				-webkit-transform: rotate(0deg);
				-moz-transform: rotate(0deg);
				-o-transform: rotate(0deg);
				transform: rotate(0deg);
				-webkit-transition: 0.25s ease-in-out;
				-moz-transition: 0.25s ease-in-out;
				-o-transition: 0.25s ease-in-out;
				transition: 0.25s ease-in-out;

				&:nth-child(1) {
					top: 0;
				}

				&:nth-child(2),
				&:nth-child(3) {
					top: 10px;
				}

				&:nth-child(4) {
					top: 20px;
				}
			}
		}
	}

	.sliding-header {
		width: 70vw;
	}

	.sliding-menu {
	  	overflow-y: auto;
		width: 70vw;
		height: calc(100% - 96px);
		background: $main-black;
		opacity: 0.99;
		position: fixed;
		top: 96px;
		left: -1000px;
		margin: 0;
		list-style: none;
		transition: 0.3s;
		box-sizing: border-box;
		z-index: 100;
		&-items {
			.menu-item, .menu-item-group {
				padding-left: 36px;
				width: 100%;
				border-bottom: 2px solid #1A1A1A;
				box-sizing: border-box;
			}

			.menu-item-group {
				.subitems-blocks {
					display: none;
					padding-bottom: 24px;
					
					.niche {
						padding: 0 0 24px 20px;
					}

					.header-button {
					 	box-sizing: border-box;
						padding: 0 16px 24px 0;
						font-weight: 400;
						font-size: 24px;
						line-height: 22px;
						&:last-child{
							padding-bottom: 0;
						}
					}

					.subitems-footer {
						font-size: 24px;
						font-weight: 400;
						.footer-title {
							color: $main-white;
							padding-bottom: 12px;
							font-weight: 700;
						}
						.footer-items {
						  	box-sizing: border-box;
						  	padding-right: 16px;
							width: 100%;
							display: flex;
							flex-direction: row;
							flex-wrap: wrap;
							gap: 24px;
							a {
								color: $dark-grey;
								&:hover {
									color: $main-white;
								}
							}
						}
					}
					&.opened {
						display: flex;
						flex-direction: column;
					}
				}
			}
		}

		&::-webkit-scrollbar{
			width: 0;
		}

		&.opened {
			left: 0;
		}

		.bottom-part {
			padding: 24px 24px 24px 36px;

			.action-button {
				padding: 10px 24px;
				width: fit-content;
				height: fit-content;
				font-weight: 600;
				font-size: 15px;
			}
		}

		.header-button {
			font-size: 24px;
			font-weight: 600;
			line-height: 22px;
			color: $dark-grey;
			outline: none;
			background-color: transparent;
			border: none;
			box-shadow: none;
			cursor: pointer;
			padding: 24px 0;
			width: 100%;
			text-align: start;
			display: flex;
			align-items: center;

			:global(svg) {
				margin-left: 6px;
			}

			&-opened {
				color: $main-white;
			}

			&:hover {
				color: $main-white;
			}
		}
	}

	.active {
		color: $green-accent !important;
	}

	@media (max-width: $portrait-phone-size) {
		header {
			padding: 24px 16px;
		}

		.sliding-menu {

			&-items {
				.menu-item, .menu-item-group {
					padding-left: 16px;
				}
				.menu-item-group {
					.subitems-blocks {
						.header-button {
							padding: 0 16px 12px 0;
							font-size: 16px;
						}

						.niche {
							padding: 0 0 12px 20px;
						}

						.subitems-footer {
							font-size: 16px;

							.footer-title {
								padding-top: 12px;
							}

							.footer-items {
								gap: 12px;
							}
						}
					}
				}
			}
			.bottom-part {
				padding-left: 16px;

				.action-button {
					font-size: 15px;
				}
			}

			.header-button {
				font-size: 16px;

			}
		}
	}
</style>
